<template>
    <div class="login-view">
        <div class="login-container">
            <div class="login-box">
                <div>
                    <h3><chillText value="login_to_your_account" /></h3>
                    <h6><chillText value="enter_login_credentials_and_accept_regulations_to_login" /></h6>
                </div>
                <div class="login-fields">
                    <div class="field"> 
                        <chillText value="email" />
                        <input type="email" v-model="email"  id="loginEmail" class="field-text email" />
                    </div>
                    <div class="field">
                        <chillText value="password" />
                        <input type="password" v-model="password"  id="loginPassword" class="email field-text" />
                    </div>
                    <div class="accept-box">
                        <chillCheckbox selenium="loginRegulations" class="regulation-box" textValue="" @input="updateBox" />
                        <a class="hyper-link" href="https://capsa-building.com/terms-of-service/" ><chillText value="accept_regulations" /></a>
                    </div>
                </div>
                <div class="button-box">
                    <chillButton value="login" type="primary inline" selenium="loginBtn" :validation="validation" v-on:on-click="login()" />
                    <div>
                        <router-link selenium="signinBtn" class="home-recent-column neutral-link" to="/signup/">
                          <chillButton value="sign_up" type="secondary inline" />
                      </router-link>
                    </div>
                </div>
                <div class="lang-box">
                    <div class="lang-box-content">
                        <chillText value="select_language"  />
                        <chillSelect :value="language" type="fixed" selenium="loginLanguage" :options="['en', 'de', 'fr', 'sv', 'es', 'it', 'nl']" @update="changeLang" />
                    </div>
                    <div class="forgot-password-content disabled">
                        <router-link selenium="signinBtn" class="home-recent-column neutral-link" to="/forgot/">
                            <chillText style="cursor: pointer;" value="forgot_password"/>
                        </router-link>
                    </div>
                </div>
            </div>
            <div class="info-box">
                <div style="display: flex; flex-direction: row; align-items: center; overflow:">
                    <div style="display: flex; flex-direction: column;">
                        <img src="../assets/logotype_primary_white@2x.png" style="width: 200px;" alt="CAPSA Logo" />
                        <!-- <p class="portal-message">If you're using the newest version of the CAPSA portal please continue to the corresponding portal.</p> -->
                        <p class="portal-message"> <chillText value="go_to_new_portal_text" /></p>
                        <a href="https://beta-portal.capsa-building.com/" class="portal-link">
                            <span>New CAPSA Portal</span>
                            <chillIcon value="farrow_right" class="external-link-icon"/>
                        </a>
                    </div>

                    <div style="width: 170px; height: 250px;">
                        <img src="../assets/macbook.png" alt="CAPSA Portal Preview" style="width: 100%; height: 110%; object-fit: contain;"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Login',
        data() {
            return {
                email: "",
                password: "",
                accepted: false,
                language: localStorage.getItem('language_web')
            }
        },
        computed: {
            validation() {
                return (this.password && this.email && this.accepted) ? true : false
            }
        },
        methods: {
            changeLang(language) { this.$store.commit('changeLang', language) },
            updateBox(event) { this.accepted = event },
            forgotPassword() {console.log('forgot password click not working')}
        }
    }
</script>
