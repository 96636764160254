<template>
    <div class="buildings-view">
        <transition name="slide-fade">
            <div class="slide-container" :id="activeFilter === true ? 'filter-active' : 'filter-hidden' ">
                <div class="filter-box-slider" >
                    <div class="filter-header"></div>
                    <div class="buildings-filter-box">
                        <div class="filter-search-field">
                            <chillTextField selenium="buildingsFilterTextSearch" class="search-field" :value="search" shorten="false" edit="true" @keyup.native="search = $event.target.value" type="full-length" :placeholder="translate('enter_text_to_search')" />
                        </div>
                        <div class="filter-select-fields">
                            <div class="select-box">
                                <chillText value="filter_key" />
                                <chillSelect selenium="buildingsFilterAttribute" class="buildings-filter" :options="filterKeys" :value="filterKey" @update="filterKey = $event"/>
                            </div>
                            <div class="select-box">
                                <chillText value="filter_condition" />
                                <chillSelect selenium="buildingsFilterCondition" :value="filterFunction" class="buildings-filter" :options="['==', '!=']" @update="filterFunction = $event"/>
                            </div>
                            <div class="select-box">
                                <chillText value="house_type" />
                                <chillSelect selenium="buildingsFilterOption" :value="filterValue" class="buildings-filter" :options="filterValues(filterKey)" @update="filterValue = $event"/>
                            </div>
                        </div>
                        <div class="filter-button-search">
                            <chillButton selenium="buildingsApplyFilter" class="buildings-filter-btn" @on-click="runFilter()" value="filter" type="primary inline" :validation="validation"/>
                        </div>
                    </div>
                </div>
                <div class="builldings-container">
                    <sidebarLeft
                        :selenium="!activeFilter ? 'openBuildingsFilter' : 'closeBuildingsFilter'"
                        :value="!activeFilter ? 'show_filter' : 'hide_filter'"
                        :icon="activeFilter ?  'arrow_right' : 'icon_filter'" @toogle-filter="activeFilter = !activeFilter"
                        :id="activeFilter === true ? 'filter-active-sidebar' : 'filter-hidden-sidebar' "
                        :filterApplied="filterApplied"
                        :filterAppliedText="filterAppliedText"
                        :filterAppliedKeys="filterAppliedKeys"
                        :savedKeyFilter="savedKeyFilter"
                        :savedTextFilter="savedTextFilter"
                        @clean-filter="cleanFilter"
                    />
                    <div class="builldings-content-container">
                        <div class="buildings-header">
                            <div class="header-filter">
                                <div class="header-filter-box">
                                    <!-- <chillText value="saved_filter" />
                                    <span class="header-filter-box-item"><chillSelect :value="usedFilter" class="buildings-filter" :options="usersFilter" @update="usedFilter = $event" /></span> -->
                                </div>
                                <div class="header-filter-box">
                                    <div>
                                        <chillText value="number_buildings" />
                                        <span class="header-filter-box-item"><chillSelect :value="limit" title="number_buildings" selenium="buildingsLimitFilter" class="buildings-filter" :options="[10, 20, 50, 100, 250, 500, 1000]"  @update="requestLimit($event)" /></span>
                                    </div>
                                    <div :class="edit ? 'edit-box-true' : 'edit-box-false'">
                                        <div :class="!edit ? 'hide-edit' : ''">
                                            <chillText value="add_new_header_keys" />
                                            <span class="header-filter-box-item header-add-new-keys"><chillSelect :value="limit" class="buildings-filter" :options="tabsKeysInfo" @update="addHeaderKey($event)" /></span>
                                        </div>
                                    </div>
                                    <div class="popup-text-columns" v-if="textPopupToMany"><chillText value="Limit of columns been exceeded. Remove existing column to add new." /></div>
                                </div>
                            </div>
                        </div>
                        <div class="buildings-list-view">
                            <div ref="dragArea" class="list-header" id="drop_zone">
                                <div class="list-header-category" draggable="false">
                                    <div class="inside">
                                        <chillText value="street_view_image" shorten="false" />
                                    </div>
                                </div>
                                <div v-for="(key, n) in headerKeys" :key="key+n" :id="key" :class="(columnFilterKey.name.split('.')[1] === key || columnFilterKey.name.split('.')[2] + '_area' === key || columnFilterKey.name.split('.')[2] === key) ? 'order-column-active' : ''" class="list-header-category column-flex drag-n-drop" draggable="true" @click="sortByColumn(key)">
                                    <div class="inside">
                                        <chillText :value="key" shorten="false" />
                                        <chillIcon v-if="edit && !(key == 'address' || key == 'city')" @click.native="deleteKey(key)" type="hover warning-icon" value="zoom_out_circle" />
                                    </div>
                                </div>
                            </div>
                            <div v-if="buildingReady" class="buildings-body" id="buildingsList">
                                <div class="list-buildings" v-for="(building, i) of fsbuildingsData" :key="i + building.id">
                                    <router-link :id="'buildingsListNr'+i" class="neutral-link" :to="'/building/'+building.id" >
                                        <div class="list-body-row">
                                            <span class="list-body-column">
                                                <chillImage :value="building.data.general.street_view_image" type="thumbnail"  />
                                            </span>
                                            <span v-for="(key, j) in headerKeys" :key="key+j" class="list-body-column column-flex"><chillText :value="cleanValue(building.data, key)" /></span>
                                        </div>
                                    </router-link>
                                </div>
                            </div>
                            <chillFooter class="chill-footer-tag" />
                        </div>
                    </div>
                    <sidebarRight :edit="edit" @toogle-edit="edit = !edit" :selenium="!edit ? 'buildingsActivateEditMode' : 'buildingsEditDeactiveEditMode'" />
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
    import building_structure_file from '../assets/json/building_structure.json'
    import building_keys_file from '../assets/json/ba_key_structure.json'
    export default {
        name: 'Buildings',
        props: { },
        data() {
            return {
                buildingReady:false,
                building_structure: building_structure_file,
                building_keys: building_keys_file,
                filterKey: '',
                filterFunction: '',
                filterValue: '',
                search: '',
                columns: [],
                edit: false,
                limit: 100,
                activeFilter: false,
                usedFilter: '',
                headerKeys: [],
                filterApplied: false,
                filterAppliedText: '',
                filterAppliedKeys: '',
                savedKeyFilter: [],
                savedTextFilter: '',
                dragEl: null,
                nextEl: null,
                section: null,
                newPos: null,
                oldPos: null,
                dragGhost: null,
                textPopupToMany: false,
                columnFilterKey: {name: '', order: ''}
            }
        },
        watch: {
            columnFilterKey: function() {
                console.log(this.columnFilterKey)
                if(this.columnFilterKey)
                    this.tabSortData(this.limit, this.columnFilterKey.order, this.columnFilterKey.name)
            },
            search: function() { this.runFilter() },
            fsHeaderColumns: function() {
                if(!this.fsHeaderColumns || Object.keys(this.fsHeaderColumns).length == 0) {
                    this.createNewFilterDoc()
                    //this.saveHeaderKey([ "address", "building_structure" ])
                } else if(this.fsHeaderColumns) {
                    this.headerKeys = this.fsHeaderColumns
                } 
            },
            headerKeys: function(newValue, oldValue)  {
                if(newValue.length > oldValue.length ) {
                    const newKey = newValue.filter(key1 => {
                        return !oldValue.some(key2 => key1 === key2 ) 
                    })
                    newKey.forEach(key => {
                        let locationKey = this.findKeyLocation(key)
                        if(!this.columns.includes(locationKey) ) { this.columns = [...this.columns, locationKey] }
                    })
                } else {
                    const deleteKey = oldValue.filter(key1 => {
                        return !newValue.some(key2 => key2 === key1 ) 
                    })
                    let locationKey = this.findKeyLocation(deleteKey[0])
                    this.columns = this.columns.filter(x => x !== locationKey )
                }
            },
        },
        computed: {
            filterKeys(){
                let vm = this
                let a = [];
                for(let tab in this.building_structure.tabs){
                    for(let section in this.building_structure.tabs[tab].sections){
                        let b = []
                        this.building_structure.tabs[tab].sections[section].content.forEach(function(k) {
                            if(vm.building_keys[k.split('.')[1]] && (vm.building_keys[k.split('.')[1]].type == "select" || vm.building_keys[k.split('.')[1]].type == "boolean")){
                                b.push(k.split('.')[1])
                            }
                        })
                        a.push.apply(a, b)
                    }
                }
                return (a)
            },
            validation() {
                return (this.filterKey && this.filterFunction && this.filterValue) ? true : false
            },
            headerKeyValues() {
                if(this.fsbuildingsData) {
                    // console.log('this.fsbuildingsData: ', this.fsbuildingsData)
                    return this.fsbuildingsData
                } 
                else {
                    return null
                }
            },
            tabsKeysInfo() {
                let list = []
                this.building_structure.tabs.forEach(x => {
                    x.sections.forEach(z => {
                        if(z.title == 'textKeys')
                            z.content.forEach(c => {
                                list.push(c.split('.')[1])
                            })
                    })
                })
                return list.concat("footprint_area", "floor_gross_area", "floor_net_area", "calculated_q_rad", "calculated_per_m2_net_q_rad")

                // TODO: Use this when the slugs and everything is updated
                // return list.concat("footprint_area", "floor_gross_area", "floor_net_area", "calculated_q_rad", "calculated_per_m2_net_q_rad", "calculated_per_m2_net_q_internal_gain", "calculated_per_m2_net_q_leak", "calculated_per_m2_net_q_roof", "calculated_per_m2_net_q_slab", "calculated_per_m2_net_q_vent", "calculated_per_m2_net_q_walls", "calculated_per_m2_net_q_windows_gain", "calculated_per_m2_net_q_windows_loss", "calculated_q_internal_gain", "calculated_q_leak", "calculated_q_rad", "calculated_q_roof", "calculated_q_slab", "calculated_q_vent", "calculated_q_walls", "calculated_q_windows_gain", "calculated_q_windows_loss")
            },
            usersFilter() {
                return this.fsHeaderColumns ? this.fsHeaderColumns : ' '
            },
        },
        methods: {
            sortByColumn(key) {
                this.buildingReady = false
                let fullKeyPath = this.building_structure.tabs[0].sections[1].content.filter(x => x.split('.')[1] === key ? x : null)
                // console.log("fff: ", fullKeyPath, key)
                if(fullKeyPath.length === 0) { fullKeyPath = this.building_structure.tabs[3].sections[1].content.filter(x => x.split('.')[1] === key ? x : null) }
                if(fullKeyPath.length === 0) {
                    if(["calculated_q_rad", "calculated_per_m2_net_q_rad", "calculated_per_m2_net_q_internal_gain", "calculated_per_m2_net_q_leak", "calculated_per_m2_net_q_roof", "calculated_per_m2_net_q_slab", "calculated_per_m2_net_q_vent", "calculated_per_m2_net_q_walls", "calculated_per_m2_net_q_windows_gain", "calculated_per_m2_net_q_windows_loss", "calculated_q_internal_gain", "calculated_q_leak", "calculated_q_rad", "calculated_q_roof", "calculated_q_slab", "calculated_q_vent", "calculated_q_walls", "calculated_q_windows_gain", "calculated_q_windows_loss"].includes(key)){
                        fullKeyPath = ["energy_balance_annual." + key]

                    }else {
                        fullKeyPath = ["deducted_data_v2.building_totals." + key.split("_area")[0]]
                    }
                }
                if(this.columnFilterKey.name === fullKeyPath[0]) {
                    if(this.columnFilterKey.order === "asc") { this.columnFilterKey = {name: fullKeyPath[0], order: "desc"} }
                    else { this.columnFilterKey = { name: fullKeyPath[0], order: "asc"} }
                }
                else {
                    console.log("adding ", fullKeyPath)
                    this.columnFilterKey = { name: fullKeyPath[0], order: "asc" }
                }
                this.buildingReady = true
            },
            // sortable(section, onUpdate) {   
            //     console.log("SORTABLE!")
            //     let vm = this
            //     function onDragOver(e) {
            //         e.preventDefault()
            //         e.dataTransfer.dropEffect = 'move'
                    
            //         let target = e.target
            //         let targetPos = target.getBoundingClientRect()
            //         if( target && target !== this.dragEl && target.nodeName == 'DIV' ) {
            //             if(target.classList.contains('inside')) {
            //                 e.stopPropagation()
            //             } else if(target.nextSibling === null) {
            //                 section.appendChild(this.dragEl)
            //             } else if( target.classList.contains('drag-n-drop') && (target.nextSibling !== null ) && (e.clientX - targetPos.left) / (targetPos.right - targetPos.left) > .5 ) {
            //                 let next = (e.clientX - targetPos.left) / (targetPos.right - targetPos.left) > .5
            //                 section.insertBefore(this.dragEl, next && target.nextSibling || target)
            //             }
            //         }
            //     }
            //     function onDragEnd(ev) {
            //         let newHeaderList = []
            //         ev.preventDefault();
            //         this.newPos = [...section.children].map(child => {
            //             let pos = document.getElementById(child.id).getBoundingClientRect()
            //             return pos
            //         });
            //         this.dragEl.classList.remove('ghost');
            //         section.removeEventListener('dragover', onDragOver, false);
            //         section.removeEventListener('dragend', onDragEnd, false);

            //         this.nextEl !== this.dragEl.nextSibling ? onUpdate(this.dragEl) : false
                    
            //         for(let i = 0; i < section.children.length; i++) {
            //             newHeaderList.push(section.children[i].id)
            //         }
            //         if(JSON.stringify(newHeaderList) !== JSON.stringify(vm.headerKeys)) {
            //             vm.saveHeaderKeysArray(newHeaderList)
            //         }
            //     }

            //     section.addEventListener('dragstart', function(e) {  
            //         this.oldPos = [...section.children].map(item => {
            //             item.draggable = true
            //             let pos = document.getElementById(item.id).getBoundingClientRect()
            //             return pos
            //         })  
            //         this.dragEl = e.target
            //         this.nextEl = this.dragEl.nextSibling
                
            //         e.dataTransfer.effectAllowed = 'move'
            //         e.dataTransfer.setData('Text', this.dragEl.textContent)
                
            //         section.addEventListener('dragover', onDragOver, false)
            //         section.addEventListener('dragend', onDragEnd, false)
                    
            //         setTimeout(function() {
            //             e.target.classList.add('ghost')
            //         }, 0)
            //     })
            // },
            addHeaderKey(key) {
                let vm = this
                if(this.headerKeys.length > 5){
                    this.textPopupToMany = true
                    setTimeout(function() {
                        vm.textPopupToMany = false
                    },3000)
                }
                else
                    this.saveHeaderKey([key])
            },
            deleteKey(key) {
                this.deleteHeaderKey(key)
            },
            requestLimit(event) {
                this.limit = Number(event)
                this.requestData(this.limit)
            },
            findKeyLocation(key) {
                let location;
                this.building_structure.tabs.forEach(x => {
                     x.sections.forEach(z => {
                        if(z.title === 'textKeys') {
                            z.content.forEach(c => {
                                if(c.split('.')[1] === key) { location = c }
                            })
                        }
                    })
                })
                if(location) return location;
                return "deducted_data_v2."+key
                // console.log(location)
                // return location
            },
            cleanFilter() {
                this.search = ""
                this.filterKey = ""
                this.filterFunction = ""
                this.filterValue = ""
                this.savedKeyFilter = []
                this.savedTextFilter = '"'
                this.runFilter()
            },
            cleanValue(data, key){
                let location = this.findKeyLocation(key)
                location = location.split('.')[0]
                // console.log("location", location, data[location], data[location]?.building_totals)
                if(["calculated_q_rad", "calculated_per_m2_net_q_rad", "calculated_per_m2_net_q_internal_gain", "calculated_per_m2_net_q_leak", "calculated_per_m2_net_q_roof", "calculated_per_m2_net_q_slab", "calculated_per_m2_net_q_vent", "calculated_per_m2_net_q_walls", "calculated_per_m2_net_q_windows_gain", "calculated_per_m2_net_q_windows_loss", "calculated_q_internal_gain", "calculated_q_leak", "calculated_q_rad", "calculated_q_roof", "calculated_q_slab", "calculated_q_vent", "calculated_q_walls", "calculated_q_windows_gain", "calculated_q_windows_loss"].includes(key) && data && data["energy_balance_annual"]){
                    if(data["energy_balance_annual"][key]) return data["energy_balance_annual"][key].toFixed(1)
                    return data["energy_balance_annual"][key]
                }
                if(data[location] && data[location]["building_totals"]){
                    return data[location]["building_totals"][key.split("_area")[0]]
                }
                if(data[location]) return data[location][key]
                else return "not_entered_yet"
            },
            filterValues(key){
                if(key == "") return []
                else if(this.building_keys[key].options) return this.building_keys[key].options
                else return ["not_entered_yet",true,false]
            },
            runFilter(){
                let f = []
                if(this.filterKey != "" && this.filterFunction != "" && this.filterValue != ""){
                    let locationKey = this.findKeyLocation(this.filterKey)
                    this.filterAppliedKeys = true
                    f.push([locationKey, this.filterFunction, this.filterValue])
                } else { this.filterAppliedKeys = false }

                if(this.search !== '' || f .length > 0) { this.filterApplied = true }
                else { this.filterApplied = false }
                    
                if(this.search !== '') {this.filterAppliedText = true}
                else { this.filterAppliedText = false }
                
                this.savedKeyFilter = f[0]
                this.savedTextFilter = this.search
                this.requestData(this.limit, {'filter':f, "search":this.search, "columns":this.columns})
            },
            translate(str) {
                let language = this.$store.state.lang
                if (typeof str == "number") {
                    return toString(str)
                } 

                if(!language){
                    // this.sleep();
                    // language = this.$store.state.lang
                    return str
                }
                else if(language === "dev"){
                    return str
                }
                else {
                    let strLower = str.toLowerCase()
                    if ( this.locales[language]['ba_web'][strLower] && this.locales[language][strLower] !== "" )
                        str = this.locales[language]['ba_web'][strLower]
                }
                return str.toLowerCase()
            },
            timeout(ms){
                return new Promise(resolve => setTimeout(resolve, ms));
            },
            async sleep(){
                await this.timeout(100);
                return true;
            }
        },
        mounted() {
            this.requestData(this.limit)
            this.startHeaderKeysListener()
            this.columnFilterKey = {name: 'general.address', order: 'asc'}
            this.buildingReady = true
            // this.sortable( document.getElementById('drop_zone'), function (){
            //     // console.log('item: ', item); 
            // });
        },
        beforeRouteLeave (to, from, next) {
            if( to.name === 'login' || to.name === 'home' || to.name === 'building' ){
                this.closeSnapshotBuildings()
            }
            setTimeout(function(){ next() }, 10)
        }
    }
</script>